import React from "react";
import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
  <section>
    <Helmet>
      <html lang="en" />
      <title>404 - No unicorns here, I'm afraid :-(</title>
      <meta name="title" content="404 - No unicorns here, I'm afraid :-("></meta>
      <meta name="description" content='Get the latest updates from data startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
    </Helmet>
    <h1>NO UNICORNS FOUND HERE <span role="img" aria-label="Sad">😥</span></h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </section>
)

export default NotFoundPage
